import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Selfie from '../assets/Headshot.jpg'

const AboutWrapper = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
  color: #c5c6c7;
  margin: 0;
  padding: 6vh 0 0 0;
  width: 100%;
  min-height: calc(100vh - 150px);

  @media screen and (max-width: 900px) {
    padding: 0;
    margin: 0;
    width: 100vw;
    max-width: 100%;
  }
`

const Block = styled.div`
  margin: 14vh auto 0 auto;
  width: 600px;

  @media screen and (max-width: 1100px) {
    padding: 16vh 12% 0 12%;
    margin: 0;
    width: 100%;
  }
`

const StyledSelfie = styled.img`
  max-width: 180px;
  max-height: 180px;
  margin-bottom: 1rem;

  @media screen and (max-width: 600px) {
    max-width: 120px;
    max-height: 120px;
  }
`

const BlockTitle = styled.h2`
  margin-top: 24px;
`

const BlockContent = styled.p`
  font-size: 15px;

  @media screen and (max-width: 1100px) {
    font-size: 14px;
  }
`

const SmallTile = styled.div`
  border: 2px solid #c5c6c7;
  border-radius: 5px;
  position: absolute;
  top: 80%;
  right: ${props => props.right};
  height: 12vh;
  width: 12vh;
  animation: ${props => props.animation} ${props => props.speed} linear infinite;

  @media screen and (max-width: 900px) {
    display: none;
  }
`

const spin1 = keyframes`
  from {
    transform:rotate(0deg);
  }

  to {
    transform:rotate(360deg);
  }
`

const spin2 = keyframes`
  from {
    transform:rotate(360deg);
  }

  to {
    transform:rotate(0deg);
  }
`

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <AboutWrapper>
          <Block>
            <StyledSelfie src={Selfie} />
            <BlockTitle>Cooper Kunz</BlockTitle>
            <BlockContent>
              I'm an Investment Partner at <a href="https://variant.fund/" rel="noreferrer" target="_blank">Variant</a>. 
              My interests include blockchain architectures, cryptoeconomics, and trying to find the world's best sour beers. 
              I've worked at <a href="https://aztec.network/" rel="noreferrer" target="_blank">Aztec</a> (Product), <a href="https://calaxy.com/" rel="noreferrer" target="_blank">Calaxy</a> (CTO), and <a href="https://hedera.com/" rel="noreferrer" target="_blank">Hedera</a> (Eng/DevRel). 
              <br/><br/>
              View my <Link to="/blog">writing</Link> or <Link to="/speaking">speaking engagements</Link>.
            </BlockContent>
            <hr />
          </Block>
          <Link to="/">
            <SmallTile
              key={Math.random()}
              animation={spin1}
              speed="8s"
              top="80%"
              right="88%"
            />
            <SmallTile
              key={Math.random()}
              animation={spin2}
              speed="8s"
              top="80%"
              right="88%"
            />
          </Link>
        </AboutWrapper>
      </Layout>
    )
  }
}

export default Index
